<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <div class="card-title">
            <span class="card-icon">
                <i class="fas fa-user-plus text-primary"></i>
            </span>
            <h3 class="card-label text-primary">
                {{"RecentTenants" | localize}}
                <small class="text-muted" *ngIf="!loading">
                    {{"RecentTenantsHelpText" | localize:recentTenantsData.recentTenantsDayCount : recentTenantsData.maxRecentTenantsShownCount}}
                </small>
            </h3>
        </div>
    </div>
    <div class="card-body" [perfectScrollbar]>
        <div class="scroller chart" [perfectScrollbar]>
            <div class="primeng-datatable-container recent-tenants-table" *ngIf="!loading"
                 [busyIf]="primengTableHelper.isLoading">
                <p-table #RecentTenantsTable [value]="recentTenantsData.recentTenants"
                         rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false"
                         [lazy]="false" [responsive]="primengTableHelper.isResponsive">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'TenantName' | localize}}</th>
                            <th>{{'CreationTime' | localize}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit">
                        <tr>
                            <td>
                                <span class="ui-column-title">{{'TenantName' | localize}}</span>
                                {{record.name}}
                            </td>
                            <td>
                                <span
                                    class="ui-column-title">{{'CreationTime' | localize}}</span>
                                {{ record.creationTime | momentFormat:'L LT'}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="recentTenantsData?.recentTenants?.length == 0">
                    {{'NoData' | localize}}
                </div>
            </div>
        </div>
        <div class="scroller-footer">
            <div class="btn-arrow-link pull-right">
                <a href="javascript:;" class="see-all-recent-tenants"
                   (click)="gotoAllRecentTenants()">{{"SeeAllRecords" | localize}}</a>
                <i class="icon-arrow-right"></i>
            </div>
        </div>
    </div>
</div>
